
.btn-primary {
    box-shadow: 0 0 0 0.1rem rgba(255,255,255,.5) !important;
}

.btn-primary:hover {
    box-shadow: 0 0 0 0.15rem rgba(255,255,255,.5) !important;
}

.btn-group.transparent > .btn {
    background-color: rgba(0,0,0,0.5) !important;
}
