#logo_normal {
    display: none;
}

@media (min-width: 680px) {
    #logo_normal {
        display: block !important;
    }

    #logo_compact {
        display: none !important;
    }
}

.navbar-toggler {
    box-shadow: 0 0 0 0.1rem rgba(255,255,255,.6) !important;
}

.navbar-toggler:hover {
    box-shadow: 0 0 0 0.15rem rgba(255,255,255,.6) !important;
}

:focus {
    outline: 0 auto !important;
}

.nav-link.active {
    border-bottom-color: white !important;
}


@media(min-width: 768px) {
    #primary-navbar {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1000;
    }

}

#primary-navbar.transparent > .navbar {
    background-color: rgba(0,0,0,0.6) !important;
}

#primary-navbar.transparent > #primaryNavbarDivider {
    background-color: rgba(255,0,0,0.6) !important;
}
